// import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";
import { marked } from "marked";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
} from "@mui/material";

export default function Law(props) {
  // const classes = useStyles();
  const cellStyle = {
    borderTop: "solid 3px #EEE",
    paddingLeft: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  return (
    <div>
      <Container>
        <Grid container spacing={1}>
          <Grid item key={"text"} xs={12}>
            <Card>
              <CardContent>
                <div style={{ height: "6vh" }}></div>
                <h2 id="transition-modal-title" align="center">
                  特定商取引法に基づく表記
                </h2>
                <div style={{ height: "6vh" }}></div>
                <table align="center" border="0">
                  <tr>
                    <th style={{ ...cellStyle, borderTop: "solid 3px #FFF" }}>
                      販売業者
                    </th>
                    <td style={{ ...cellStyle, borderTop: "solid 3px #FFF" }}>
                      Lamact
                    </td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>運営責任者</th>
                    <td style={cellStyle}>藤田薫哉</td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>所在地</th>
                    <td style={cellStyle}>
                      東京都江東区（詳細は
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScyKsJZsiBWTioMVKagYE7rDwNBIGAg3qZMXXwldfC2yYzxNg/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        お問い合わせ
                      </a>
                      により必要と判断すれば回答します）
                    </td>
                  </tr>

                  <tr>
                    <th style={cellStyle}>連絡先</th>
                    <td style={cellStyle}>
                      lamact.inquiry@gmail.com
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>販売価格</th>
                    <td style={cellStyle}>
                      【月額費用】 Lonpa Standard 100円（税込）
                      <br />
                      【月額費用】 Lonpa Pro 5,000円（税込）
                    </td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>商品代金以外の必要料金</th>
                    <td style={cellStyle}>振込手数料</td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>支払方法</th>
                    <td style={cellStyle}>
                      クレジットカード決済
                      <br />
                      <a
                        href="https://stripe.com/jp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Stripe
                      </a>
                      の決済代行システムを利用しております。
                      <br />
                      なお、支払日はカード会社、その他金融機関が指定する引き落とし日になります。
                    </td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>キャンセル等</th>
                    <td style={cellStyle}>
                      返品・返金はできませんのでご了承ください。
                    </td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>中途解約について</th>
                    <td style={cellStyle}>
                      月の途中など契約期間途中の解約となった場合も
                      <br />
                      契約満了日までの料金が発生し、
                      <br />
                      日割清算等による返金を含めた一切の返金は行われません。
                    </td>
                  </tr>
                  <tr>
                    <th style={cellStyle}>動作環境</th>
                    <td style={cellStyle}>chromeを推奨いたします</td>
                  </tr>
                </table>
                <div style={{ height: "6vh" }}></div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
