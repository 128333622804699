// import { useStyles } from "../Styles";
import { useNavigate } from "react-router-dom";
import { marked } from "marked";
import { Typography, Container, Grid, Card, CardContent } from "@mui/material";

export default function PrivacyPolicy(props) {
  // const classes = useStyles();

  const markdown = `                                                                                                                                                                                                                                                                                                                                                                                                                           \n
本規約は、Lamact（以下「当社」といいます。）は、当社がLonpa（以下「本サービス」といいます。）を提供するにあたり、ご利用される皆様（以下「利用者」といいます。）の個人に関する情報（以下「個人情報」といいます。）を取得します。  
### 第１条（適用範囲）
本プライバシーポリシー（以下「本ポリシー」といいます。）は、当社が利用者から個人情報を取得、利用及び管理するときに適用されます。

### 第２条（取得する情報）
当社は、利用者から以下の情報を取得します。  

（1）氏名  
（2）住所  
（3）生年月日  
（4）年齢  
（5）連絡先  
（6）クレジットカード情報、銀行口座情報等の決済に関する情報  
（7）法律上の要請等により本人確認を行うための運転免許証、健康保険証、住民票の写し等の本人確認情報に含まれる情報  
（8）情報通信端末の機体識別に関する情報  
（9）位置情報  
（10）IPアドレス  
（11）閲覧したURL及び日時に関するタイムスタンプ  

### 第３条（利用目的）
当社が個人情報を収集・利用する目的は、以下のとおりです。  

（1）本サービスの提供・運営のため  
（2）本サービスの運営上必要な事項の通知のため  
（3）本サービスの会員である利用者の管理のため  
（4）広告効果の分析、市場分析、マーケティングのため  
（5）本サービスの各種問合せ、アフターサービス対応のため  
（6）不正アクセス、不正利用の防止のため  
（7）上記の利用目的に付随する目的  

### 第４条（Cookieの利用）  
当社は、ウェブサイト及び本サービス（以下、これらを総称して「ウェブサイト等」といいます。）のアクセス及び利用状況の分析、広告配信並びにウェブサイト等の利便性向上のために、ウェブサイト等にアクセスした利用者の情報通信端末に保存されるCookie等のファイル及びこれに類似する技術を取得することがあります。当社は、Cookie等と利用者の個人情報を関連付けることがあります。当社は、利用者の個人情報と関連付けたCookie等が利用者の個人情報であることに鑑み、当該Cookie等を本ポリシーの定めに従って管理いたします。また、当社は、Cookie等の情報を第三者が運営するデータ・マネジメント・プラットフォームに提供し、当該第三者からCookie等により収集されたウェブの閲覧履歴及びその分析結果を取得し、これらを利用者の個人データと結び付けた上で、広告配信等の目的で利用することがあります。  

### 第５条（安全確保の措置）
当社は、収集した情報の漏えい、滅失又はき損の防止その他収集した情報の適切な管理のために必要な措置を講じます。  

（1）個人情報を取り扱うことのできる機器やアクセス権者を明確にし、個人情報への不要なアクセスを防止  
（2）メール等により取扱う際に、パスワードを設定  

### 第６条（個人情報の第三者への提供）
１ 当社は、次に掲げる場合を除いて、あらかじめ利用者の同意を得ないで、取得した個人情報を第三者に提供することはありません。  
（１）法令に基づく場合  
（２）人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であるとき。  
（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき。  
（４）国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。  
（５）その他法令で第三者提供の制限の例外が認められている場合  
２ 前項の定めにかかわらず、次に掲げる場合には、当該個人情報の提供先は第三者に該当しないものとします。  
（１）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って当該個人情報が提供される場合  
（２）合併その他の事由による事業の承継に伴って個人情報が提供される場合  
（３）第7条に定める共同利用者に対して提供される場合  

### 第７条（本プライバシーポリシーの変更）
当社は、法令改正への対応の必要性及び事業上の必要性に応じて、本ポリシーを変更する場合があります。本ポリシーの変更を行った場合には、本ウェブサイト上に掲載します。  

### 第８条（開示、訂正等の手続）
１ 利用者は、本条及び当社の関連規程に従って、当社に対し、個人情報保護法において認められる限度で、以下の求め又は請求を行うことができます。
（１）個人情報の利用目的の通知の求め  
（２）個人情報又は第三者提供記録の開示の請求  
（３）個人情報の訂正、追加又は削除の請求  
（４）個人情報の利用の停止の請求  
（５）個人情報の第三者への提供の停止の請求  
２ 前項の求め又は請求にあたっては、同項各号のうちいずれの請求か特定の上、本人確認のための書類（運転免許証、健康保険証、住民票の写し等）をご提出頂きます。  
３ 第1項第1号の求め又は第2号の請求については、1件につき、1,000円の手数料をご負担頂きますのであらかじめご了承ください。  

### 第９条（お問い合わせ）
当社の個人情報の取扱いに関するご相談や苦情等のお問い合わせについては、お問い合わせ窓口にご連絡ください。                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
 `;

  return (
    <div>
      <Container>
        <Grid container spacing={1}>
          <Grid item key={"text"} xs={12}>
            <Card>
              <CardContent>
                <Typography>
                  <div style={{ height: "6vh" }}></div>
                  <h2 id="transition-modal-title" align="center">
                    プライバシーポリシー
                  </h2>
                  <div style={{ height: "6vh" }}></div>
                  <span
                    dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
