import React from "react";
import styles from "../styles/Yamauchi.module.css";

type Props = {
  imageURL: any;
  charaName: String;
  charaDiscription: String;
};

const Character: React.VFC<Props> = ({
  imageURL,
  charaName,
  charaDiscription,
}: Props) => {
  return (
    <>
      {" "}
      <div className={styles.chara_container}>
        <div className={styles.chara_detail}>
          <div className={styles.chara_name}>{charaName}</div>
          <p className="{styles.chara_profile}">{charaDiscription}</p>
        </div>
        <div className={styles.chara_canvas}>
          <img src={imageURL} alt="Yamauchi" className={styles.image} />
        </div>
        <br />
      </div>
    </>
  );
};

export default Character;
