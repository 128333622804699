import React from "react";
import { useState } from "react";
import y1 from "../image/yamauchi/y1.png";
import o1 from "../image/yamauchi/o1.png";
import h1 from "../image/yamauchi/h1.png";

import Character from "./Yamauchi/Character";

import { Grid, Button } from "@mui/material";

const Yamauchi = () => {
  const buttonList = [
    { imageURL: y1, charaName: "yamauchi" },
    { imageURL: o1, charaName: "osada" },
  ];
  const [openmenu, setOpenmenu] = useState("yamauchi");
  const [imageURL, setImageURL] = useState<any>(y1);

  return (
    <div>
      <h1>Character</h1>
      <Grid container spacing={2}>
        {buttonList.map((item: any, index: number) => (
          <Grid item key={index}>
            <Button
              variant="contained"
              style={{ borderRadius: "50%", padding: "10px" }}
              onClick={() => {
                setOpenmenu(item.charaName);
                setImageURL(item.imageURL);
              }}
            >
              <img
                src={item.imageURL}
                alt={item.charaName}
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
            </Button>
          </Grid>
        ))}
      </Grid>

      {openmenu === "yamauchi" ? (
        <>
          <Character
            imageURL={imageURL}
            charaName={"山内さん"}
            charaDiscription={
              "普段はしっかり者で気遣い上手だが、気を許したら気ままに絡んでくる。お散歩好きで、晴れた日は厚底スニーカーで気の向くままに歩いている。お酒が好きだけど弱く、たまにほろ酔いで散歩している。迷子のところを長田に助けてもらって以来、すっかり懐いている。"
            }
          />
        </>
      ) : openmenu === "osada" ? (
        <>
          <Character
            imageURL={imageURL}
            charaName={"長田さん"}
            charaDiscription={
              "デスクワークを淡々とこなすサラリーマン。肩と首のコリは慢性的な悩み。たまにぼそっと妙なことを言う抜け感があり、それが周囲を和ませる。山内からは「おさポン、散歩行こ〜」といつも誘われている。街中の小さなオブジェ等を発見するのが得意．"
            }
          />
        </>
      ) : (
        <>ここは表示されないはず</>
      )}
      <div className="town">
        <img src={h1} alt="Haikei" style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default Yamauchi;
